import { useNavigate } from "react-router-dom";

import HeaderTab from "@/components/layouts/common/HeaderTab";
import Loading from "@/components/layouts/common/Loading";
import LoginModal from "@/components/layouts/login/LoginModal";
import UpperContent from "@/components/layouts/top/UpperContent";
import useAuth from "@/hooks/useAuth";
import useRedirectStep from "@/hooks/useRedirectStep";

function HeaderPage() {
  useRedirectStep();
  const navigate = useNavigate();
  const {
    isTopPath,
    isLogin,
    isRegisterPage,
    isSm,
    key,
    isOpenMenue,
    menuRef,
    user,
    colorStyle,
    isOpenLoginMenue,
    isLoginModal,
    isReady,
    setIsOpenLoginMenue,
    setIsLoginModal,
    setIsOpenMenue,
    handleProfile,
    handleLogout,
    handleLogin,
    moveLogin,
  } = useAuth();

  // /tl以下はLPページ
  const isLpPage = location.pathname.includes("/tl");
  if (!isReady || isLpPage) return <></>;
  return (
    <div className='z-50 bg-opacity-30 font-sans'>
      {location.pathname === "/" && (
        <div className='z-[-1]'>
          <UpperContent />
        </div>
      )}
      <div className={`${isTopPath ? "absolute top-[0]" : ""} w-full`}>
        {user.id && !isRegisterPage ? (
          <nav className='mb-[9px] flex w-full items-center pt-4 sm:h-[56px] md:mb-0 md:h-[80px] md:pl-8'>
            <button className={`min-w-[180px] ${isSm ? "" : "mr-8 pb-4"}`} onClick={() => navigate("/")}>
              <img src={`/images/logo${isSm ? "-phone" : ""}.svg`} alt='logo' />
            </button>
            {key.component !== "profile" &&
              key.component !== "settings" &&
              key.component !== "detail" &&
              key.component !== "footer" &&
              location.pathname !== "/service" && (
                <div className='flex w-2/5 justify-end sm:w-fit'>
                  <HeaderTab />
                </div>
              )}
            {!key.isLoading && (
              <div className='flex pb-2 pr-8'>
                <div className='flex-grow' />
                <button className='text-[14px] font-bold' onClick={() => setIsOpenMenue((prev) => !prev)}>
                  <p className='absolute right-8 top-6 md:top-8'>
                    {user.last_name === '' && user.first_name === '' && user.email ? user.email : user.last_name + '' + user.first_name}
                  </p>
                </button>
              </div>
            )}
            {isOpenMenue && (
              <div
                ref={menuRef}
                className='fixed z-10 h-fit w-[150px] rounded-md border bg-white p-4 shadow-lg sm:right-[10px] sm:top-[70px] md:right-[40px] md:top-[90px]'
              >
                <ul className='text-[13px] text-gray-600'>
                  <li className='mb-2'>
                    <button onClick={handleProfile} className='hover:text-gray-100'>
                      プロフィール
                    </button>
                  </li>
                  <li className='mb-2'>
                    <a
                      href={user.register_step === 0 ? "/register/legal" : "/settings"}
                      className='hover:text-gray-100'
                    >
                      設定
                    </a>
                  </li>
                  <li className='mb-2'>
                    <button onClick={handleLogout} className='hover:text-gray-100'>
                      ログアウト
                    </button>
                  </li>
                </ul>
              </div>
            )}
          </nav>
        ) : isRegisterPage ? (
          <nav className='flex items-center pt-4 sm:mb-4 sm:h-[56px] md:mb-0 md:h-[80px] md:pl-8'>
            <button className='min-w-[180px]' onClick={() => navigate("/")}>
              <img src='/images/logo.svg' alt='logo' className='sm:ml-2 md:ml-0 md:mr-16 md:pb-4' />
            </button>
          </nav>
        ) : (
          <nav className='flex w-full items-center pt-4 sm:mb-4 sm:h-[56px] md:mb-0 md:h-[80px] md:pl-8'>
            <button className='w-1/5' onClick={() => navigate("/")}>
              <img
                src={`/images/logo${isSm ? "-phone" : ""}.svg`}
                alt='logo'
                className={`mr-${isSm ? "7" : "8"} pb-${isSm ? "2" : "5"}`}
              />
            </button>
            {isSm && key.component !== "detail" && key.component !== "footer" && location.pathname !== "/service" && (
              <div className='ml-4 flex w-2/5 justify-end sm:w-fit'>
                <HeaderTab />
              </div>
            )}
            {!isSm && !key.isLoading ? (
              <div className='flex w-full items-center justify-end pb-4 pr-8 text-right' id='a'>
                {key.component !== "detail" && key.component !== "footer" && location.pathname !== "/service" && (
                  <div
                    className={`${
                      key.component !== "footer" &&
                      location.pathname !== "/service" &&
                      "sm-min-w-[120px] mt-[16px] min-w-[210px]"
                    }`}
                  >
                    <HeaderTab />
                  </div>
                )}
                <a
                  href='https://business.kasooku.jp/'
                  className='ml-4 flex justify-start'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <p className='mr-1 pt-1 font-bold hover:text-gray-600'>採用したい企業様はこちら</p>
                  <img src='/images/arrow-top-right.svg' alt='arrow' />
                </a>
                <div className='flex-grow' />
                <a
                  className='ml-2 flex min-w-[135px] cursor-pointer items-center rounded-full border border-gray-200 bg-white px-10 py-3 text-center text-[13px] font-bold leading-4 text-[#3c447c] hover:text-[#8597cd] focus:outline-none sm:block sm:w-full md:w-fit'
                  onClick={() => moveLogin()}
                >
                  <span>ログイン</span>
                </a>
                <a
                  className='ml-2 flex min-w-[135px] rounded-full px-6 py-3 text-center text-[13px] font-bold leading-4 text-gray-200 hover:text-gray-400 sm:block sm:w-full md:w-fit'
                  href={"/register/legal"}
                  style={colorStyle}
                >
                  新規会員登録
                </a>
              </div>
            ) : (
              <div className='mr-2 flex w-1/5 justify-end sm:absolute sm:right-4'>
                <button
                  style={colorStyle}
                  className='mb-1 ml-2 flex h-[44px] w-[44px] items-center justify-center rounded-md'
                  onClick={() => handleLogin()}
                >
                  <img
                    src={`/images/${isOpenLoginMenue ? "x-mark" : "bars"}.svg`}
                    alt={isOpenLoginMenue ? "Close" : "Menu"}
                    className={`${isOpenLoginMenue ? "ml-6 mr-5 mt-3 h-8 w-8 rounded-lg" : "h-5 w-5"}`}
                  />
                </button>
              </div>
            )}
            {isOpenLoginMenue && (
              <div
                ref={menuRef}
                className='fixed bottom-0 z-10 rounded-lg border-2 border-[#3c447c] bg-white sm:right-2 sm:top-[70px] sm:h-fit sm:w-1/2 md:right-60 md:top-[120px] md:h-[320px] md:w-[320px] md:p-4'
              >
                <LoginModal
                  isLoginModal={isLoginModal}
                  setIsLoginModal={setIsLoginModal}
                  setIsOpenLoginMenue={setIsOpenLoginMenue}
                />
              </div>
            )}
          </nav>
        )}
      </div>
      <Loading />
    </div>
  );
}

export default HeaderPage;
